
    import { Component, Prop, Vue } from 'nuxt-property-decorator';
    import {
        AvailableGroups,
        AvailableTypes,
        ComponentMeta
    } from '@/components/editor/config/types';
    import OptionsFieldsInlineEditor from '@/components/editor/options/fields/OptionsFieldsInlineEditor.vue';
    import ImageComponent from '@/components/blocks/ImageComponent.vue';
    import Modal from '@/components/blocks/Modal.vue';
    import BlogComponentWrapper from '@/components/blocks/blog/BlogComponentWrapper.vue';

    interface HeaderWithPicBlock extends ComponentMeta {}

    export const meta: HeaderWithPicBlock = {
        title: 'Текст с картинкой (вкл/выкл)',
        options: {
            text: {
                title: 'Текст',
                type: AvailableTypes.editor,
                default:
                    'Полезные статьи: технологии, разбор ошибок, трендовые сувениры, маркетинг, продажи, производство...'
            },
            image: {
                title: 'Изображение',
                type: AvailableTypes.image,
                default: {
                    src: '/block-default/HeaderWithPic/urgent-work.jpg'
                }
            },
            additionalComponent: {
                type: AvailableTypes.additionalComponent
            }
        },
        group: AvailableGroups.Блог
    };
    @Component({
        components: {
            BlogComponentWrapper,
            Modal,
            ImageComponent,
            OptionsFieldsInlineEditor
        }
    })
    export default class HeaderWithPic extends Vue {
        @Prop() text;
        @Prop() image;
        @Prop() component;
        @Prop() additionalComponent;

        modalId = 'modalImage' + Math.floor(Math.random() * 101);

        click() {
            this.$bvModal.show(this.modalId);
        }
    }
