
    import { Component, Prop } from 'nuxt-property-decorator';
    import { AvailableGroups, AvailableTypes } from '@/components/editor/config/types';
    import OptionsFieldsInlineEditor from '@/components/editor/options/fields/OptionsFieldsInlineEditor.vue';
    import imageMixin from '@/mixins/imageMixin';
    import ImageComponent from '@/components/blocks/ImageComponent.vue';
    import BlogComponentWrapper from '@/components/blocks/blog/BlogComponentWrapper.vue';

    const striptags = require('striptags');

    export const meta = {
        title: 'Текст с номерами/иконками',
        options: {
            head: {
                title: 'Основной заголовок',
                type: AvailableTypes.editor,
                default: '<p>Как же тогда сделать заказ?</p>'
            },
            headIcon: {
                title: 'Иконка для заголовка',
                type: AvailableTypes.image,
                default: {
                    src: '/block-default/ParagraphWithHeadings/check.svg'
                }
            },
            headNumber: {
                title: 'Номер для заголовка',
                type: AvailableTypes.string,
                default: '1'
            },
            items: {
                type: AvailableTypes.array,
                title: 'Пункты',
                item: {
                    subtitle: {
                        title: 'Подзаголовок',
                        type: AvailableTypes.editor,
                        default: 'Определяем задачу'
                    },
                    subtitleIcon: {
                        title: 'Иконка для подзаголовка',
                        type: AvailableTypes.image,
                        default: {
                            src: '/block-default/ParagraphWithHeadings/check.svg'
                        }
                    },
                    subtitleNumber: {
                        title: 'Номер для подзаголовка',
                        type: AvailableTypes.string,
                        default: '1'
                    },
                    text: {
                        title: 'Текст',
                        type: AvailableTypes.editor,
                        default:
                            'При выборе лекал нам нужно решить одну из двух задач: либо аудиторию, для которой вы заказываете футболки нужно усреднить, либо наоборот — подчеркнуть индивидуальность отдельного человека или группы людей, объединенных одной идеей. Что значит «усреднить» в данном случае — подобрать лекала, которые подойдут на несколько размеров, либо не будут подчеркивать половую принадлежность особенностями кроя.'
                    }
                }
            },

            additionalComponent: {
                type: AvailableTypes.additionalComponent
            }
        },
        group: AvailableGroups.Блог
    };

    @Component({
        components: {
            BlogComponentWrapper,
            ImageComponent,
            OptionsFieldsInlineEditor
        }
    })
    export default class ParagraphWithHeadings extends imageMixin {
        @Prop() head;
        @Prop() headIcon;
        @Prop() headNumber;
        @Prop() items;
        @Prop() component;
        @Prop() additionalComponent;

        striptags = striptags;

        parseNumber(digitString) {
            digitString =
                digitString.length > 2 ? digitString.substring(0, 2) : digitString;

            const number = parseInt(digitString) || 1;

            return number > 9 ? number.toString() : '0' + number.toString();
        }

        get noPadding() {
            return this.items.every(
                ({ subtitleIcon, subtitleNumber }) => !subtitleIcon.src && !subtitleNumber
            );
        }

        editorTextLength(text) {
            return striptags(text).length;
        }
    }
