
    import { Component, Prop } from 'nuxt-property-decorator';
    import { AvailableGroups, AvailableTypes } from '@/components/editor/config/types';
    import OptionsFieldsInlineEditor from '@/components/editor/options/fields/OptionsFieldsInlineEditor.vue';
    import ImageComponent from '@/components/blocks/ImageComponent.vue';
    import BlogComponentWrapper from '@/components/blocks/blog/BlogComponentWrapper.vue';
    import BreadCrumbs from '@/components/blocks/BreadCrumbs.vue';
    import { formatDate } from '@/utils';
    import ImageMixin from '@/mixins/imageMixin';

    export const meta = {
        title: 'Главный блок. Блог 2',
        options: {
            hidePath: {
                title: 'Скрывать хлебные крошки',
                type: AvailableTypes.boolean,
                default: false
            },
            mainImage: {
                title: 'Заглавное изображение',
                type: AvailableTypes.image,
                default: {
                    src: '/block-default/HeaderFullImage/HeaderFullImage.jpg'
                }
            },
            header: {
                title: 'Текст заголовка',
                type: AvailableTypes.editor,
                default: 'Делаем поло уникальной и узнаваемой'
            },
            text: {
                title: 'Текст',
                type: AvailableTypes.editor,
                default:
                    '<p><span style="font-size: 24px"><span style="line-height: 1.3"><span><span style="color: #484848"><span style="font-family: Five years later">В этой статье мы расскажем, как можно разнообразить дизайн стандартной поло, поможем сориентироваться в возможностях рынка и предложим несколько идей, которые, надеемся, вас вдохновят.</span></span></span></span></span></p>'
            },
            date: {
                title: 'Дата',
                type: AvailableTypes.date,
                default: '2020-01-01'
            },
            additionalComponent: {
                type: AvailableTypes.additionalComponent
            }
        },
        group: AvailableGroups.Блог
    };
    @Component({
        components: {
            BlogComponentWrapper,
            ImageComponent,
            OptionsFieldsInlineEditor,
            BreadCrumbs
        }
    })
    export default class HeaderFullImage extends ImageMixin {
        @Prop() component;
        @Prop() mainImage;
        @Prop() header;
        @Prop() text;
        @Prop() date;
        @Prop() socials;
        @Prop() tableContents;
        @Prop() additionalComponent;
        @Prop() hidePath;

        get formattedDate() {
            return formatDate(this.date);
        }

        isVideo(image) {
            return image.src.indexOf('.mp4') !== -1;
        }

        isGif(image) {
            return image.src.indexOf('.gif') !== -1;
        }
    }
