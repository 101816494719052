
    import { Component, Prop } from 'nuxt-property-decorator';
    import PageBlockMixin from '@/mixins/pageBlockMixin';
    import ImageComponent from '@/components/blocks/ImageComponent.vue';
    import OptionsFieldsInlineEditor from '@/components/editor/options/fields/OptionsFieldsInlineEditor.vue';
    import { AvailableGroups, AvailableTypes } from '@/components/editor/config/types';
    import BlogComponentWrapper from '@/components/blocks/blog/BlogComponentWrapper.vue';

    export const meta = {
        title: 'Картинки с текстом',
        options: {
            head: {
                type: AvailableTypes.editor,
                title: 'Заголовок',
                default: 'Вышивка'
            },
            items: {
                title: 'Картинка с текстом',
                type: AvailableTypes.array,
                item: {
                    image: {
                        type: AvailableTypes.image,
                        title: 'Картинка',
                        default: {
                            src: '/block-default/ImageWithText/3shite.jpg'
                        }
                    },
                    textForImage: {
                        type: AvailableTypes.string,
                        title: 'Текст для картинки',
                        default:
                            'Когда раскрой делает один подрядчик, \n' +
                            'а печатает другой.'
                    },
                    text: {
                        type: AvailableTypes.string,
                        title: 'Текст',
                        default:
                            'К нам часто обращаются клиенты, которые хотят сделать рубашку-поло уникальной \n' +
                            'и узнаваемой, подчеркнуть свой фирменный стиль и выделиться \n' +
                            'на фоне конкурентов. \n' +
                            'К сожалению, часто оказывается, \n' +
                            'что их дизайны хороши только \n' +
                            'на бумаге, но их невозможно выполнить технологически или это экономически нецелесообразно.'
                    },
                    changeOrder: {
                        type: AvailableTypes.boolean,
                        title: 'Переместить изображение'
                    }
                }
            },
            additionalComponent: {
                type: AvailableTypes.additionalComponent
            }
        },
        group: AvailableGroups.Блог
    };

    @Component({
        components: { BlogComponentWrapper, OptionsFieldsInlineEditor, ImageComponent }
    })
    export default class ImageWithText extends PageBlockMixin {
        @Prop() component;
        @Prop() items;
        @Prop() additionalComponent;

        mounted() {}

        get contentBorder() {
            return this.additionalComponent !== undefined
                ? 'image-with-text__content_bordered'
                : '';
        }
    }
