
    import { Component, mixins, Prop } from 'nuxt-property-decorator';
    import PageBlockMixin from '@/mixins/pageBlockMixin';
    import Button from '@/components/blocks/Button.vue';
    import BaseForm from '@/components/blocks/base/BaseForm.vue';
    import FormMixin from '@/mixins/formMixin';
    import Form from '@/components/blocks/Form.vue';
    import OptionsFieldsInlineEditor from '@/components/editor/options/fields/OptionsFieldsInlineEditor.vue';
    import { AvailableGroups, AvailableTypes } from '@/components/editor/config/types';

    export const meta = {
        title: 'Подписка на рассылку',
        options: {
            head: {
                type: AvailableTypes.editor,
                title: 'Заголовок',
                default: 'Подпишитесь на наши статьи'
            },
            form: {
                type: AvailableTypes.component,
                componentName: 'Form'
            }
        },
        group: AvailableGroups.Блог
    };

    @Component({
        components: {
            OptionsFieldsInlineEditor,
            Form,
            BaseForm,
            Button
        }
    })
    export default class Subscribe extends mixins(PageBlockMixin, FormMixin) {
        @Prop() component;
        @Prop() head;
        @Prop() form;
    }
