
    import { Component, Prop, Vue } from 'nuxt-property-decorator';
    // @ts-ignore
    import { AvailableGroups, AvailableTypes } from '@/components/editor/config/types';
    import OptionsFieldsInlineEditor from '@/components/editor/options/fields/OptionsFieldsInlineEditor.vue';

    export const meta = {
        title: 'Ссылки на соц сети',
        options: {
            text: {
                type: AvailableTypes.editor,
                title: 'Заголовок',
                default: 'В статье использовались фотографии с ресурса ссылка'
            },
            links: {
                title: 'Социальные сети',
                type: AvailableTypes.array,
                item: {
                    link: {
                        title: 'Ссылка',
                        type: AvailableTypes.string
                    },
                    btnText: {
                        title: 'Текст кнопки',
                        type: AvailableTypes.string
                    }
                },
                default: [
                    { btnText: 'Подписаться', link: 'https://vk.com/pinq_ru' },
                    { btnText: 'Подписаться', link: 'https://www.facebook.com/pinq.ru/' },
                    {
                        btnText: 'Подписаться',
                        link: 'https://www.instagram.com/pinq.ru/'
                    },
                    {
                        btnText: 'Подписаться',
                        link: 'https://www.youtube.com/channel/UCYGT_n0SKIrxbSQ9hm5efZg}'
                    }
                ]
            }
        },
        group: AvailableGroups.Блог
    };

    @Component({ components: { OptionsFieldsInlineEditor } })
    export default class SocialLinks extends Vue {
        @Prop() text;
        @Prop() links;
        @Prop() component;

        getSocialService(link) {
            if (link && typeof link === 'string') {
                if (link.indexOf('vk.com') !== -1) {
                    return 'brands/vk';
                }
                if (link.indexOf('facebook.com') !== -1) {
                    return 'brands/facebook-f';
                }
                if (link.indexOf('instagram.com') !== -1) {
                    return 'brands/instagram';
                }
                if (link.indexOf('youtube.com') !== -1) {
                    return 'brands/youtube';
                }
                return 'cog';
            }
        }
    }
