
    import { Component, Prop } from 'nuxt-property-decorator';
    import PageBlockMixin from '@/mixins/pageBlockMixin';
    import OptionsFieldsInlineEditor from '@/components/editor/options/fields/OptionsFieldsInlineEditor.vue';
    import {
        AvailableGroups,
        AvailableTypes,
        ComponentMeta
    } from '@/components/editor/config/types';
    import striptags from 'striptags';
    import BlockBorder from '@/components/common/BlockBorder.vue';
    import BlogComponentWrapper from '@/components/blocks/blog/BlogComponentWrapper.vue';

    export const meta: ComponentMeta = {
        title: 'Текст в рамке',
        options: {
            head: {
                type: AvailableTypes.editor,
                title: 'Заголовок',
                default: 'Есть такое выражение: «Всякая экономия требует затрат»'
            },
            text: {
                type: AvailableTypes.editor,
                title: 'Текст',
                default:
                    'Мы понимаем желание своих клиентов сэкономить и сделать заказ дешевле. Но попытка экономить на всем оборачивается дополнительными затратами, потерей времени, \n' +
                    'срывом сроков, лишними усилиями. Самое главное: изделия получаются низкого \n' +
                    'качества, что для нас неприемлемо. А для клиента такой заказ — лишние расходы.\n'
            },
            textWithColor: {
                type: AvailableTypes.editor,
                title: 'Цветной текст',
                default:
                    'По этой причине мы берем на себя все этапы производства и не допускаем вмешательства \n' +
                    'в производственный процесс, потому что только \n' +
                    'так можем на 100% отвечать за результат.\n'
            },
            additionalComponent: {
                type: AvailableTypes.additionalComponent
            }
        },
        group: AvailableGroups.Блог
    };

    @Component({
        components: { BlockBorder, OptionsFieldsInlineEditor, BlogComponentWrapper }
    })
    export default class TextFramed extends PageBlockMixin {
        @Prop() head;
        @Prop() text;
        @Prop() textWithColor;
        @Prop() additionalComponent;
        striptag = striptags;
    }
