<script>
    import { Component, mixins, Prop } from 'nuxt-property-decorator';
    import ImageComponent from '@/components/blocks/ImageComponent.vue';
    import { AvailableGroups, AvailableTypes } from '@/components/editor/config/types';
    import OptionsFieldsInlineEditor from '@/components/editor/options/fields/OptionsFieldsInlineEditor';
    import PageBlockMixin from '@/mixins/pageBlockMixin';
    import PaletteMixin from '@/mixins/paletteMixin';
    import BlogComponentWrapper from './BlogComponentWrapper';

    export const meta = {
        title: 'Картинка на весь экран с текстом',
        options: {
            image: {
                title: 'Изображения',
                type: AvailableTypes.image,
                default: {
                    src: '/block-default/PictureWithText/ImageWithText.jpg'
                }
            },
            text: {
                title: 'Текст',
                type: AvailableTypes.editor,
                default:
                    'Когда раскрой делает один подрядчик, а печатает другой, детали разных размеров могут перепутать.'
            },
            additionalComponent: {
                type: AvailableTypes.additionalComponent
            }
        },

        group: AvailableGroups.Блог
    };

    @Component({
        components: { BlogComponentWrapper, OptionsFieldsInlineEditor, ImageComponent }
    })
    export default class PictureWithText extends mixins(PageBlockMixin, PaletteMixin) {
        @Prop() component;
        @Prop() image;
        @Prop() text;
        @Prop() additionalComponent;
    }
</script>

<template>
    <section class="picture-with-text">
        <b-container>
            <blog-component-wrapper :component="additionalComponent">
                <b-col lg="9" offset-lg="1">
                    <div class="picture-with-text__content">
                        <div class="picture-with-text__image" v-if="image">
                            <image-component
                                :img="image"
                                class="picture-with-text__image__size"
                            />
                        </div>
                        <div class="picture-with-text__text">
                            <options-fields-inline-editor
                                :component="component"
                                :keyOption="text"
                                :value="text"
                            />
                        </div>
                    </div>
                </b-col>
            </blog-component-wrapper>
        </b-container>
    </section>
</template>

<style lang="scss" scoped>
    .picture-with-text {
        padding-bottom: 3.125rem;

        &__text {
            margin-top: 1rem;
            height: 2rem;
            font-family: $ff;
            font-size: 0.875rem;
            line-height: 1.25rem;
            color: rgba($dark-gray, 0.6);
        }

        &__image {
            &__size {
                max-width: 100%;
            }
        }
    }

    @include media-breakpoint-down(sm) {
        .picture-with-text {
            padding-bottom: 2.625rem;

            &__text {
                margin-top: 0.75rem;
                font-size: 0.625rem;
                line-height: 0.9375rem;
            }
        }
    }
</style>
